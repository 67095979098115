import React from "react"
import Layout from "../components/layout"

export default () => (
  <Layout>
    <h1>Thank you!</h1>
    <p>
      This is a custom thank you page for form submissions whici i havent
      implemented because of cost
    </p>
  </Layout>
)
